<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Instellingen</h1>
  
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/event'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Evenement</h3>
          <p class="text-muted mb-0">Bewerk de gegevens van je evenement.</p>
        </b-card>
  
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/tickets'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Tickets</h3>
          <p class="text-muted mb-0">Bewerk de gegevens van je tickets.</p>
        </b-card>
  
        <!--
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/theme'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Thema</h3>
          <p class="text-muted mb-0">Bewerk het thema van je tickets.</p>
        </b-card>
        -->
  
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/emails'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">E-mails</h3>
          <p class="text-muted mb-0">Personaliseer de teksten in de e-mails die verstuurd worden.</p>
        </b-card>
  
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/personal-data'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Persoonsgegevens</h3>
          <p class="text-muted mb-0">Stel in welke gegevens je wilt weten van je klanten.</p>
        </b-card>
  
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/coupons'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Kortingscodes</h3>
          <p class="text-muted mb-0">Beheer gebruikers die toegang hebben tot het dashboard.</p>
        </b-card>
  
        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/links'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Koppelingen</h3>
          <p class="text-muted mb-0">Koppel externe diensten zoals Mailchimp en Tag Manager.</p>
        </b-card>

        <b-card class="mb-3">
          <b-button class="float-right mt-2 ml-2" variant="primary" :to="'/manage/events/' + $route.params.id + '/settings/webshop'">Bewerken</b-button>
          <h3 class="fs-5 mb-0">Webshop</h3>
          <p class="text-muted mb-0">Pas de weergave van je webshop aan.</p>
        </b-card>
  
      </b-container>
    </div>
  </template>
  
<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'

  export default {
    metaInfo: {
      title: 'Dashboard',
    },
    components: {
    },
    data() {
      return {
        events: []
      }
    },
    computed: {},
    methods: {
      ...mapActions(useShopStore, ['setLoading']),
    },
    created() {},
  }
</script>